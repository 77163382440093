import React from 'react'
import useMediaQuery from '../hooks/useMediaQuery'
import { motion } from 'framer-motion';
import LineGradient from '../components/LineGradient';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const isAboveMediumScreens = useMediaQuery("(min-width:1060px)");
    const [trans, i18n] = useTranslation("global");

    return (
        <section id="services" className="pt-10 pb-24">
            {/* HEADER AND IMAGE SECTION */}
            <div className="md:flex md:justify-between md:gap-16 mt-32">
                <motion.div
                    className='md:w-[40%]'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 }
                    }}
                >
                    <p className="font-playfair font-semibold text-4xl mb-5">
                        {trans("services.our")} <span className="text-red">{trans("services.services")}</span>
                    </p>

                    <LineGradient width="w-1/3" />

                    <p className="mt-10 mb-7">{trans("services.detail")}</p>
                </motion.div>

                <div className="mt-16 md:mt-0">
                    {
                        isAboveMediumScreens ? (
                            <div className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10 before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]">
                                <img
                                    src="/assets/nails-services.jpg"
                                    alt="skills"
                                    className='z-10 max-w-[500px] max-h-[400px]' />
                            </div>
                        ) : (
                            <img
                                src="/assets/nails-services.jpg"
                                alt="skills"
                                className='z-10' />
                        )
                    }
                </div>
            </div>

            {/* PRODUCTS */}
            <div className="md:flex md:justify-between mt-16 gap-[3rem]">
                {/* Manicure */}
                <motion.div
                    className='md:w-1/4'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: -50 },
                        visible: { opacity: 1, y: 0 }
                    }}
                >
                    <div className='relative h-32'>
                        <div className='z-10'>
                            <p className='font-playfair font-semibold text-5xl'>01</p>
                            <p className='font-playfair font-semibold text-3xl mt-3'>{trans("services.manicure")}</p>
                        </div>
                        <img src='/assets/manicure.svg' alt='manicure' className="absolute right-0 top-0 z-[-1] rounded-full bg-green" />
                    </div>

                    <p className="">{trans("services.manicure-detail")}</p>
                </motion.div>

                {/* PEDICURE */}
                <motion.div
                    className='md:w-1/4'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: -50 },
                        visible: { opacity: 1, y: 0 }
                    }}
                >
                    <div className='relative h-32'>
                        <div className='z-10'>
                            <p className='font-playfair font-semibold text-5xl'>02</p>
                            <p className='font-playfair font-semibold text-3xl mt-3'>{trans("services.pedicure")}</p>
                        </div>
                        {/* <div className='w-1/2 md:w-3/4 h-32  absolute right-0 top-0 z-[-1]' /> */}
                        <img src='/assets/pedicure-3.svg' alt='pedicure' className="absolute right-0 top-0 z-[-1] rounded-full bg-red" />
                    </div>

                    <p className="">{trans("services.pedicure-detail")}</p>
                </motion.div>

                {/* NAIL ART */}
                <motion.div
                    className='md:w-1/4'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: -50 },
                        visible: { opacity: 1, y: 0 }
                    }}
                >
                    <div className='relative h-32'>
                        <div className='z-10'>
                            <p className='font-playfair font-semibold text-5xl'>03</p>
                            <p className='font-playfair font-semibold text-3xl mt-3'>{trans("services.nail-art")}</p>
                        </div>
                        {/* <div className='w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]' /> */}
                        <img src='/assets/nail-art.svg' alt='pedicure' className="absolute right-0 top-0 z-[-1] rounded-full bg-yellow" />
                    </div>

                    <p className="">{trans("services.nail-art-detail")}</p>
                </motion.div>

                {/* EYE-Lashes */}
                <motion.div
                    className='md:w-1/4'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: -50 },
                        visible: { opacity: 1, y: 0 }
                    }}
                >
                    <div className='relative h-32'>
                        <div className='z-10'>
                            <p className='font-playfair font-semibold text-5xl'>04</p>
                            <p className='font-playfair font-semibold text-3xl mt-3'>{trans("services.lashes")}</p>
                        </div>
                        <img src='/assets/eye-lashes.svg' alt='manicure' className="absolute right-0 top-0 z-[-1] rounded-full bg-blue" />
                    </div>

                    <p className="">{trans("services.lashes-detail")}</p>
                </motion.div>
            </div>
        </section>
    )
}

export default Services
