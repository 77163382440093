import React from 'react'
import { motion } from 'framer-motion';
import LineGradient from '../components/LineGradient';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../hooks/useMediaQuery';

const container = {
    hidden: {},
    visible: {
        transition: { staggerChildren: 0.1 }
    },
};

const projectVariant = {
    hidden: {
        opacity: 0,
        scale: 0.8
    },
    visible: {
        opacity: 1,
        scale: 1
    },
}

const Gallery = ({ title }) => {
    // const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
    // bg-grey z-30 flex flex-col justify-center items-center text-center p-16 text-deep-blue`;

    const projectTitle = title.split(" ").join("-").toLowerCase();

    return (
        <motion.div variants={projectVariant} className="relative">
            {/* <div className={overlayStyles}>
                <p className="text-2xl font-playfair">{title}</p>
                <p className="mt-7">
                    Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla
                    porttitor accumsan tincidunt.
                </p>
            </div> */}
            <img src={`../assets/${projectTitle}.jpg`} alt={projectTitle} />
        </motion.div>
    )
}

const Galleries = () => {
    const [trans, i18n] = useTranslation("global");
    const isAboveSmallScreens = useMediaQuery("(min-width: 768px)");

    return (
        <section id="gallery" className='pt-48 pb-18'>
            {/* HEADING */}
            <motion.div
                className='md:w-[56%] mx-auto text-center'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, y: -50 },
                    visible: { opacity: 1, y: 0 }
                }}
            >
                <div>
                    <p className='font-playfair font-semibold text-4xl'>
                        <span className='text-red'>{trans("galleries.why")} </span>{trans("galleries.choose-us")}
                    </p>
                    <div className='flex justify-center mt-5'>
                        <LineGradient width='w-1/3' />
                    </div>
                </div>

                <p className="mt-10 mb-10">{trans("galleries.detail")}</p>
            </motion.div>

            {/* PRODUCTS */}
            <div className='flex justify-center'>
                <motion.div
                    className='grid xxs:grid-cols-1 sm:grid-cols-3'  
                    initial={`${isAboveSmallScreens}? "hidden":"visible"`}
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    variants={container}
                >
              
                    <div className='flex justify-center text-center items-center p-10 bg-red max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold'>{trans("galleries.make-your-day")}</div>
                    <Gallery title="Product 1" />
                    <Gallery title="Product 2" />


                    <Gallery title="Product 3" />
                    <Gallery title="Product 4" />
                    <Gallery title="Product 5" />


                    <Gallery title="Product 7" />
                    <Gallery title="Product 8" />
                    <div className='flex justify-center text-center items-center p-10 bg-blue max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold'>{trans("galleries.make-elegance")}</div>
                </motion.div>
            </div>
        </section>
    )
}

export default Galleries
