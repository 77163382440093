import { color } from 'framer-motion';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

const TranslationButton = ({textColor, borderRightColor}) => {
    const selectedStyle = `${textColor} text-[18px] transition duration-500 font-bold`;
    const [trans, i18n] = useTranslation("global");
    const [selectedLanguage, setSelectedLanguage] = useState("en");

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
    }

    return (
        <div className="flex">
            {/* <button className={`border-r-2 border-r-red px-1 ${selectedLanguage === "en" ? selectedStyle : ""}`} onClick={()=> handleChangeLanguage("en")}>EN</button> */}
            <button className={`border-r-2 ${borderRightColor} px-1 ${selectedLanguage === "en" ? selectedStyle : ""}`} onClick={()=> handleChangeLanguage("en")}>EN</button>
            <button className={`px-1 ${selectedLanguage === "de" ? selectedStyle : ""}`} onClick={()=> handleChangeLanguage("de")}>DE</button>
        </div>
    )
}

export default TranslationButton
