import React from 'react'

const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-4 gap-7 py-2">
      <a className="hover:opacity-50 transition duration-500 hover:scale-[1.5]"
        href="https://www.facebook.com/profile.php?id=100063767073291"
        target="_blank"
        rel="noreferrer"
      >
        <img src="../assets/facebook.png" alt="facebook-link"/>
      </a>

      <a className="hover:opacity-50 transition duration-500 hover:scale-[1.5]"
        href="https://www.instagram.com/luxurynails.beauty/"
        target="_blank"
        rel="noreferrer"
      >
        <img src="../assets/instagram-2.png" alt="instagram-link"/>
      </a>

      <a className="hover:opacity-50 transition duration-500 hover:scale-[1.5]"
        href="https://youtu.be/C39dcpuU224"
        target="_blank"
        rel="noreferrer"
      >
        <img src="../assets/youtube-2.png" alt="youtube-link"/>
      </a>
    </div>
  )
}

export default SocialMediaIcons
