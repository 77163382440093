import React from 'react'
import useMediaQuery from '../hooks/useMediaQuery'
import { motion } from "framer-motion";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import SocialMediaIcons from '../components/SocialMediaIcons';
import { useTranslation } from 'react-i18next';

const Landing = ({ setSelectedPage }) => {
    const isAboveMediumScreens = useMediaQuery("(min-width:1060px)");
    const [trans, i18n] = useTranslation("global");

    return (
        <section id="home" className="md:flex md:justify-between md:items-center md:h-full gap-16 py-10">
            {/* IMAGE SECTION */}
            <div className="md:order-2 flex justify-center basis-3/5 z-10 mt-16 md:mt-28">
                {isAboveMediumScreens ? (
                    <div className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-20  before:w-full before:max-w-[400px] before:h-full before:border-2 before:border-blue before:z-[-1]">
                        <img
                            src="/assets/nails-art-3.jpg"
                            alt="profile"
                            className='hover:filter transition duration-500 z-10 w-full max-w-[400px] max-h-[600px]' />
                    </div>
                ) : (
                    <img
                        alt="profile"
                        className="z-10 w-full max-w-[400px] md:max-w-[600px]"
                        src="assets/nails-art-3.jpg"
                    />
                )}
            </div>

            {/* MAIN SECTION */}
            <div className="z-30 basis-1/3 mt-12 md:mt-32">
                {/* HEADING */}
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 }
                    }}
                >
                    <div className="xs:relative">
                        <p className="slogan font-playfair text-3xl position:absolute text-center  md:text-deep-blue xs:font-semibold z-20 md:before:content-brush before:absolute md:before:-left-[-50px] lg:before:-left-[-70px] before:-top-[82px] before:z-[-1] before:scale-y-[125] ">Best Nails For <br /> Best Moments</p>
                    </div>

                    <p className='mt-12 mb-7 text-sm text-center '>
                        {trans("landing.intro")}
                    </p>
                </motion.div>

                {/* CALL TO ACTIONS */}
                <motion.div
                    className='flex mt-5 justify-center md:justify-start'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 }
                    }}
                >
                    <a
                        className="w-40 bg-gradient-rainblue text-deep-blue text-center rounded-sm py-3 px-7 font-semibold hover:bg-blue hover:text-white hover:scale-[1.1] transition duration-500 mr-2"
                        href="tel:+4917675626789"
                    >
                        {trans("landing.call-us")}
                    </a>

                    <AnchorLink
                        className="rounded-r-sm bg-gradient-rainblue p-0.5 pr-0.5 w-40 hover:scale-[1.1] transition duration-500"
                        onClick={() => setSelectedPage("contact")}
                        href="#contact"
                    >
                        <div className="bg-deep-blue hover:text-red transition duration-500 w-full h-full flex items-center justify-center font-playfair px-4">
                            {trans("landing.let-book")}
                        </div>
                    </AnchorLink>
                </motion.div>

                {/* SOCIAL MEDIA ICONS */}
                <motion.div
                    className='flex mt-5 justify-center md:justify-start'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 }
                    }}
                >
                    <SocialMediaIcons />
                </motion.div>
            </div>
        </section>
    )
}

export default Landing
