import React, { useState } from "react";
import "./Modal.css";
import useMediaQuery from "../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";

const Modal = () => {
    const [modal, setModal] = useState(false);
    const isAboveSmallScreens = useMediaQuery("(min-width: 436px)");
    const [trans, i18n] = useTranslation("global");

    const toggleModal = () => {
        setModal(!modal);
    };

    return (
        <>
            <button
                className="btn-modal rounded-r-sm bg-gradient-rainblue py-0.5 w-44 hover:scale-[1.1] transition duration-500"
                onClick={toggleModal}
            >
                <div className="bg-deep-blue hover:text-red transition duration-500 w-full h-full flex items-center justify-center font-playfair px-4">             
                    {trans("price-button.get-price")}
                </div>
            </button>

            {modal && (
                <div className="modal text-deep-blue z-30">
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="modal-content bg-yellow xxs:min-w-[400px] xs:min-w-[500px]">
                        <div>
                            {isAboveSmallScreens && <img src="/assets/price.png" alt="price" className="h-[38rem]" />}
                            {!isAboveSmallScreens && <img src="/assets/price-2.png" alt="price" className="h-[34rem]" />}
                        </div>

                        <button className="close-modal hover:scale-125 transition duration-500" onClick={toggleModal}>
                            <img src="/assets/close-2.png" alt="price" className="" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default Modal