import React from 'react'
import SocialMediaIcons from '../components/SocialMediaIcons'

const Footer = () => {
  return (
    <footer className="h-30 bg-red">
      <div className="w-5/6 mx-auto">
        <div className="md:flex justify-center md:justify-between text-center items-center">
          <SocialMediaIcons />

          <p className="text-md">
            Copyright &copy; 2022 Luxury Nails. Designed by HUY KAISER.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
