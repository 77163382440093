import { useTranslation } from "react-i18next";
import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const Testimonials = () => {
  const [trans, i18n] = useTranslation("global");
  const feedbackStyle = "mx-auto relative max-w-[400px] h-[400px] flex flex-col justify-end p-8 mt-48 before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2";
  const scrollBarStyle = "overflow-y-auto mt-8 h-[230px]";


  return (
    <section id="feedbacks" className="pt-32 pb-16">
      {/* HEADING */}
      <motion.div
        className="md:w-1/3 text-center md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-playfair font-semibold text-4xl mb-5 text-red">{trans("feedbacks.feedbacks")}</p>

        <LineGradient width="mx-auto w-2/5" />

        <p className="mt-10">{trans("feedbacks.detail")}</p>
      </motion.div>

      {/* FEEDBACKS */}
      <div className="md:flex md:justify-between gap-8">
        <motion.div
          className={`${feedbackStyle} before:content-person1 bg-blue`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <div className={`${scrollBarStyle}`}>
            <p className="font-playfair text-6xl h-[30px]">“</p>
            <p className="text-center text-lg">{trans("feedbacks.feedback-1")}</p>
          </div>
        </motion.div>

        <motion.div
          className={`${feedbackStyle} before:content-person3 bg-red`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <div className={`${scrollBarStyle}`}>
            <p className="font-playfair text-6xl h-[30px]">“</p>
            <p className="text-center text-lg">{trans("feedbacks.feedback-2")}</p>
          </div>
        </motion.div>

        <motion.div
          className={`${feedbackStyle} before:content-person2 bg-yellow`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <div className={`${scrollBarStyle}`}>
            <p className="font-playfair text-6xl h-[30px]">“</p>
            <p className="text-center text-lg">{trans("feedbacks.feedback-3")}</p>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Testimonials;