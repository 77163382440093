import React from 'react'
import { useForm } from 'react-hook-form'
import { motion } from "framer-motion";
import LineGradient from "../components/LineGradient";
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const [trans, i18n] = useTranslation("global");
    // const { register, trigger, formState: { errors } } = useForm();

    // const onSubmit = async (e) => {
    //     const isValid = await trigger();

    //     if (!isValid) {
    //         e.preventDefault();
    //     }
    // }

    return (
        <section id="contact" className="pt-48 pb-8">
            {/* HEADING */}
            <motion.div
                className='flex justify-end w-full'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                }}
            >
                <div>
                    <p className="font-playfair font-semibold text-4xl">
                        <span className='text-yellow'>{trans("contact.visit-us")}</span>
                    </p>
                    <div className='flex md:justify-end my-5'>
                        <LineGradient width="w-1/2" />
                    </div>
                </div>
            </motion.div>

            {/* INFOS & GOOGLE MAP */}
            <div className='md:flex md:justify-between gap-16 mt-5'>
                <motion.div
                    className='basic-1/2 flex justify-center'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <div className='w-full'>
                        <ul>
                            <li className='flex gap-4 py-2 items-center'>
                                <img src="../assets/marker.png" alt="map" className="basis-1/8 h-full" />
                                <span className="basis-7/8 flex items-center">{trans("contact.salon-1")}</span>
                            </li>
                            <li className='flex gap-4 py-2 items-center'>
                                <img src="../assets/marker.png" alt="map" className="basis-1/8 h-full" />
                                <span className="basis-7/8 flex items-center">{trans("contact.salon-2")}</span>
                            </li>
                            <li className='flex gap-4 py-2 items-center'>
                                <img src="../assets/phone.png" alt="map" className="basis-1/8 h-full" />
                                <span className="basis-7/8 flex items-center hover:bg-deep-blue hover:scale-[1.1] transition duration-500">
                                    <a
                                        className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold hover:text-white transition duration-500 min-w-[262px]"
                                        href="tel:+4917675626789"
                                    >
                                        +49 176 7562 6789 (Coburg)
                                    </a>
                                </span>
                            </li>
                            <li className='flex gap-4 py-2 items-center w-full'>
                                <img src="../assets/phone.png" alt="map" className="basis-1/8 h-full" />
                                <span className="basis-7/8 flex items-center hover:bg-deep-blue hover:scale-[1.1] transition duration-500">
                                    <a
                                        className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold hover:text-white transition duration-500 min-w-[262px]"
                                        href="tel:+491741539989"
                                    >
                                        +49 174 153 9989 (Suhl)
                                    </a>
                                </span>
                            </li>
                            <li className='flex gap-4 py-2 items-center'>
                                <img src="../assets/mail.png" alt="map" className="basis-1/8 h-full" />
                                <span className="basis-7/8 flex items-center">luxurynailsnagelstudio@gmail.com</span>
                            </li>
                            <li className='flex gap-4 py-2 items-center'>
                                <img src="../assets/open-sign.png" alt="map" className="basis-1/8 h-full" />

                                <span className="basis-7/8">
                                    <div className="grid grid-cols-2 gap-10 xl:gap-16"><span className="text-red">{trans("contact.mo-fr")}:</span><span className="text-red">9:30 - 19:00</span></div>
                                    <div className="grid grid-cols-2 gap-10 xl:gap-16"><span className="text-red">{trans("contact.saturday")}:</span><span className="text-red">9:30 - 17:00</span></div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </motion.div>

                <motion.div
                    className='basic-1/2 mt-10 md:mt-0'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    {/* <form
                        target='_blank'
                        onSubmit={onSubmit}
                        action='https://formsubmit.co/vogiahuy97@gmail.com'
                        method='POST'
                    >
                        <input
                            className='w-full bg-blue font-semibold placeholder-opaque-black p-3'
                            type="text"
                            placeholder='NAME'
                            {...register("name", {
                                required: true,
                                maxLength: 100
                            })}
                        />

                        {errors.name && (
                            <p className='text-red mt-1'>
                                {errors.name.type === "required" && "This field is required."}
                                {errors.name.type === "maxLength" && "Max Length is 100 char."}
                            </p>
                        )}

                        <input
                            className='w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5'
                            type="text"
                            placeholder='EMAIL'
                            {...register("email", {
                                required: true,
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}
                        />

                        {errors.email && (
                            <p className="text-red mt-1">
                                {errors.email.type === "required" && "This field is required."}
                                {errors.email.type === "pattern" && "Invalid email address."}
                            </p>
                        )}

                        <textarea
                            className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5"
                            name="message"
                            placeholder="MESSAGE"
                            rows="4"
                            cols="50"
                            {...register("message", {
                                required: true,
                                maxLength: 2000,
                            })}
                        />
                        {errors.message && (
                            <p className="text-red mt-1">
                                {errors.message.type === "required" &&
                                    "This field is required."}
                                {errors.message.type === "maxLength" &&
                                    "Max length is 2000 char."}
                            </p>
                        )}

                        <button
                            className="p-5 bg-yellow font-semibold text-deep-blue mt-5 hover:bg-red hover:text-white transition duration-500"
                            type="submit"
                        >
                            SEND ME A MESSAGE
                        </button>
                    </form> */}

                    <div>
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d637.6717499185429!2d10.963334790659731!3d50.26042990071616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a3cfab3e642f55%3A0xde3e6432b679dc1b!2sLuxuryNails%26Hair!5e0!3m2!1sen!2sde!4v1700152496476!5m2!1sen!2sde"
                            width={1000}
                            height={400}
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            className='max-w-full mx-auto my-0' />
                    </div>
                </motion.div>
            </div>
        </section>
    )
}

export default Contact
